<template>
    <b-modal
        id="addMarkModal"
        centered
        :title="mark._id ? 'Editar marcador' : 'Criar marcador'"
        header-border-variant="light"
        footer-border-variant="light"
        content-class="p-4 position-relative"
        body-class="position-unset"
        :no-close-on-backdrop="loading"
        :no-close-on-esc="loading"
        @hide="close"
    >
        <loading v-if="loading" />

        <validation-observer ref="addMarkForm">
            <validation-provider
                #default="{ errors }"
                rules="required"
                class="w-100">
                <input
                    v-model="mark.name"
                    type="text"
                    class="form-control m-0"
                    :class="{ 'is-invalid': errors.length > 0 }"
                    placeholder="Nome">
            </validation-provider>
            <validation-provider
                #default="{ errors }"
                rules="required"
                class="w-100">
                <textarea
                    v-model="mark.description"
                    type="text"
                    class="form-control m-0 my-3"
                    :class="{ 'is-invalid': errors.length > 0 }"
                    style="resize: none;"
                    placeholder="Descrição"
                    rows="3"
                ></textarea>
            </validation-provider>
            <div class="d-flex justify-content-center">
                <color-picker v-model="mark.color"></color-picker>
            </div>
        </validation-observer>

        <template #modal-footer="{ cancel, ok }">
            <button @click="cancel" class="btn btn-outline-primary px-5 mr-2">
                Cancelar
            </button>
            <button @click="saveMark(ok)" class="btn btn-primary px-5">
                Salvar
            </button>
        </template>
    </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ColorPicker from 'vue-color-picker-wheel';
import { BModal } from "bootstrap-vue";
import Loading from "@/views/components/Loading.vue";
import { required } from "@validations";
import api from "@/api";

const markModel = {
    name: null,
    description: null,
    color: ''
};

export default {
    components: {
        BModal,
        ColorPicker,
        ValidationProvider,
        ValidationObserver,
        Loading
    },
    props: {
        value: {
            type: Object,
            required: false
        }
    },
    data() {
        return {
            required,
            mark: Object.assign({}, markModel),
            loading: false
        };
    },
    watch: {
        "$props.value"(val) {
            console.log(val)
            this.mark = Object.assign({}, val ? val : markModel);
        }
    },
    created() {
        Object.assign(this.mark, markModel);
    },
    emits: ['save', 'input'],
    methods: {
        close() {
            this.$emit('input', null);
            this.mark = Object.assign({}, markModel);
        },
        saveMark(closeHandle) {
            this.$refs.addMarkForm.validate().then(success => {
                if(!success) return;

                this.loading = true;

                const method = this.mark._id ? 'put' : 'post';
                const route = this.mark._id ? `marks/${this.mark._id}` : 'marks';
                api[method](route, this.mark)
                .then(response => {
                    const { type, message, body } = response.data;

                    if(type === 'success') {
                        this.$bvToast.toast(message, {title: 'Marcadores', variant: 'success'});
                        this.$emit('save', body);
                        this.mark = Object.assign({}, markModel);
                        this.$refs.addMarkForm.reset();
                        return closeHandle()
                    }

                    throw message;
                })
                .catch(error => {
                    let message = 'Não foi possível salvar marcador.'
                    if(error.response) {
                        message = error.response.data.message;
                    }
                    if(typeof error == 'string') {
                        message = error
                    }
                    console.log(`saveMark: ${error}`);
                    this.$bvToast.toast(message, {title: 'Marcadores', variant: 'danger'});
                })
                .finally(() => this.loading = false);
            })
        },
    },
}
</script>

<style>
.position-unset {
    position: unset !important;
}
</style>
